import React from 'react';
import './week.css'; 

function Week17Recap() {
  return (
    <div className="week-recap"> 
      <h2>Week 17 Recap</h2>
      <p>
      In a closely contested game against the Los Angeles Rams, the New York Giants fell short once again, losing 26-25 in the penultimate week of the regular season. Despite the loss, the Giants showed resilience, bringing a playoff contender down to the wire for the second consecutive game.
</p>

<h1> Key Offensive Points</h1>
<p>
Tyrod Taylor, who started at quarterback after his performance in the previous game against the Eagles, completed 27 of 41 passes for 319 yards and a touchdown. However, he was sacked six times.
</p>

<p>
Taylor connected with Darius Slayton on an 80-yard touchdown, showcasing a deep connection that has been effective in recent games.
</p>

<p>
Wan'Dale Robinson contributed with a 24-yard rushing touchdown on an end-around, tying the game early in the second quarter.
</p>

<h1> Defensive Highlights</h1>
<p>
Rookie cornerback Deonte Banks missed the game due to a shoulder injury, and Nick McCloud started in his place. Safety Jason Pinnock left in the first quarter with a foot injury.
</p>

<p>
Safety Dane Belton intercepted Rams quarterback Matthew Stafford, ending Stafford's streak of consecutive games with multiple passing touchdowns and no interceptions.
</p>

<p>
Belton had an impactful game, recovering a fumble from Rams wide receiver Demarcus Robinson after Adoree' Jackson punched the ball away.
</p>

<p>
Despite the challenges and injuries, the Giants demonstrated resilience and competitiveness against a formidable opponent. The season finale against the Philadelphia Eagles offers an opportunity to end the season on a positive note.
</p>
</div>
  );
}

export default Week17Recap;
